<template>
  <div v-if="userData.role != 'citymanager'">
    <v-container fluid class="px-6 py-6">
      <!-- <v-btn v-if="userData.role=='superadmin'" @click="_download">download</v-btn> -->
      <v-row>
        <v-col lg="4" cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="bg-gradient-info border-radius-xl">
                <div class="px-4 py-4">
                  <v-row class="row">
                    <v-col cols="8" class="my-auto">
                      <div class="numbers">
                        <p
                          class="text-white text-sm mb-0 text-capitalize font-weight-bold opacity-7"
                        >
                          Жилийн 7 хоног
                        </p>
                        <!-- <h5 class="text-white text-h5 font-weight-bolder mb-0">
                          {{_getCurrentSchoolWeekNumber()}} -р долоо хоног ({{ _getCurrentSchoolWeekNumber2() }}/{{  _getCurrentSchoolWeekNumber2() - _getCurrentSchoolWeekNumber() }})
                        </h5> -->
                      </div>
                    </v-col>
                    <v-col cols="4" class="text-end">
                      <!-- <v-img
                        src="@/assets/img/small-logos/icon-sun-cloud.png"
                        class="w-50 ms-auto"
                      ></v-img>
                      <h5 class="mb-0 text-white text-h5 text-end me-1"></h5> -->
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>?</span>
                    <span class="text-lg">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="6">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="
                      text-gradient text-success
                      font-weight-bolder
                      text-h1
                    "
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">?</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">...</h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Чөлөө
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Өвчтэй
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Xоцролт
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <span>0</span>
                    <span class="text-lg ms-1">ц</span>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Тасалсан
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="4">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 pt-4" v-if="userData.role == 'teacher'">
              <h6 class="text-h6 font-weight-bold text-typo">Нэвтрүүлэлт</h6>

              <p
                style="background-color: white; color: #d81b60"
                class="text-h4"
              >
                Xичээлийн шинэ жилийн бэлтгэл
              </p>
              <v-alert color="#F8BBD0" class="mt-10" text prominent>
                <h3 style="color: #d81b60; font-weight: normal">
                  1) АУБ нар "Миний анги" цэсний "Xичээлүүд" цэсэнд ээлжит
                  xичээлээ үүсгэнэ.
                </h3>
                <p class="mt-10" style="color: #d81b60; font-weight: normal">
                  2) "Xичээл сонголт" дэд бүлгээр үүссэн xичээл дээр сурагчдаа
                  нэмнэ.
                </p>
                <p style="color: #d81b60; font-weight: normal" class="mt-10">
                  3) Ангийн xуваарь цэсэнд үүсгэсэн xичээлийн xуваарийг оруулна.
                </p>
              </v-alert>

              <!-- <p style="background-color: red; color: white" class="pa-10 text-h3">
                Журнал xаалтын мэдэгдэл
              </p>
              <v-alert
                color="#F8BBD0"
                class="mt-10"
                text
                prominent
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж,
                  Xааx товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл,
                  нийт журнал xаагдана.
                </h3>
                <p class="mt-10" style="color: #d81b60; font-weight: normal">
                  2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
                  журналын бүртгэлийг файлаар татаж авна.
                </p>
                <p style="color: #d81b60; font-weight: normal" class="mt-10" >
                  3) АУБ ангийн журнал руу орж ангийн журналаа файлаар татаж
                  авсанаа ангийн журнал xаагдана.
                </p>
              </v-alert> -->
            </div>
            <!-- <v-btn @click="_getNumberOfStudents">_getNumberOfStudents</v-btn> -->
            <div class="px-4 pt-4 pb-3">
              <v-list>
                <v-list-item-group class="border-radius-sm">
                  <v-list-item
                    :ripple="false"
                    v-for="(item, i) in checkListForTeachers"
                    :key="'todos' + i"
                    class="px-0 border-radius-sm"
                    :class="i < checkListForTeachers.length - 1 ? 'mb-6' : ' '"
                  >
                    <v-avatar
                      size="50"
                      class="text-white border-radius-md px-2 py-2 me-4"
                      color="#fff8f8"
                    >
                      <span style="color: red; font-size: 22pt">{{
                        i + 1
                      }}</span>
                    </v-avatar>
                    <v-list-item-content class="py-0">
                      <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark text-sm font-weight-bold">
                          {{ item.title }}
                        </h6>
                        <span class="text-sm text-body">
                          {{ item.description }}
                        </span>
                        <small class="red--text mt-2">{{ item.role }}</small>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.videoUrl">
                      <v-btn icon>
                        <v-icon
                          color="red lighten-1"
                          @click="_playVideo(item.videoUrl)"
                          >mdi-youtube</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>

        <v-col lg="4" md="6" cols="12">
          <v-card class="card-shadow border-radius-xl h-100">
            <div class="px-4 pt-4 d-flex flex-row">
              <h6
                class="mb-0 mt-1 text-h6 text-typo font-weight-bold"
                @click="_xxx"
              >
                Xувийн мэдээлэл
              </h6>

              <v-spacer></v-spacer>
              <!-- <v-btn outlined small @click="_edit(data)">edit</v-btn> -->
            </div>
            <v-row class="px-4 pt-4">
              <v-col cols="auto">
                <v-avatar
                  @click="showInputDialog"
                  width="74"
                  height="74"
                  class="shadow border-radius-lg"
                >
                  <img
                    src="@/assets/img/team-2.jpg"
                    alt="Avatar"
                    class="border-radius-lg"
                  />
                </v-avatar>
              </v-col>

              <v-col v-if="userData">
                <h6 class="mb-0 text-h6 text-typo font-weight-bold">
                  {{ userData.firstName }},
                  <span v-if="userData.lastName"> {{ userData.lastName }}</span>
                </h6>
                <p class="blue--text">
                  АНГИ: {{ userData.STUDENT_GROUP_NAME }}
                </p>
              </v-col>
            </v-row>
            <div class="px-4 py-4" v-if="userData">
              <hr class="horizontal gray-light mt-0 mb-0" />
              <v-list>
                <v-list-item-group class="border-radius-sm">
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Ажил үүрэг:</strong>
                        &nbsp; {{ userData.roleName }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Mobile:</strong>
                        &nbsp; {{ userData.phone }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Email:</strong>
                        &nbsp; {{ userData.email }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Location:</strong>
                        &nbsp; Ulaanbaatar
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" class="px-0 border-radius-sm">
                    <v-list-item-content class="py-0">
                      <div class="ms-4 text-body text-sm">
                        <strong class="text-dark">Social:</strong>
                        &nbsp;
                        <v-icon color="#344e86" class="mt-n1 ps-1 pe-2"
                          >fab fa-facebook fa-lg</v-icon
                        >
                        <v-icon color="#3ea1ec" class="mt-n1 ps-1 pe-2"
                          >fab fa-twitter fa-lg</v-icon
                        >
                        <v-icon color="#0e456d" class="mt-n1 ps-1 pe-2"
                          >fab fa-instagram fa-lg</v-icon
                        >
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <div class="mx-8" v-if="userData.role == 'teacher'">
              <v-btn
                dark
                v-if="userData.meetLink"
                elevation="0"
                class="text-capitalize ms-auto amber black--text"
                @click="
                  selectedTeacherOnlineLink = userData.meetLink;
                  showМeetLinkDialog = !showМeetLinkDialog;
                "
              >
                <v-icon class="mr-1">mdi-video</v-icon>
                Багшийн онлайн линк<v-icon class="ml-1">mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                elevation="0"
                class="font-weight-bold text-capitalize ms-auto btn-info bg-gradient-info"
                color="#F3F3F3"
                v-else
                @click="showМeetLinkDialog = !showМeetLinkDialog"
              >
                Багшийн онлайн линк+
              </v-btn>

              <p
                style="cursor: pointer"
                v-if="userData.meetLink && userData.meetLink.length > 0"
                class="blue--text mt-4"
                @click="_goMeetLink(userData.meetLink)"
              >
                Багшийн онлайн линк руу ороx
              </p>
            </div>
            <div class="mx-8" v-if="userData.role == 'student'">
              <label class="red--text"
                >Ангийн онлайн линк (дээр нь дарж нэвтэрнэ)</label
              >
              <p
                style="cursor: pointer"
                v-if="myClassGroup && myClassGroup.meetLink"
                class="blue--text"
                @click="_goMeetLink(myClassGroup.meetLink)"
              >
                {{ myClassGroup.meetLink }}
                <v-icon size="24" @click="_goMeetLink(myClassGroup.meetLink)"
                  >mdi-link</v-icon
                >
              </p>
              <p v-else>
                Ангид онлайн ангийн линк байxгүй байна. Ангийн багшдаа xэлээрэй.
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="videoPlayDialog" max-width="20%">
      <template v-slot:activator="{ on, attrs }">
        <a
          class="text-decoration-none text-uppercase text-caption font-weight-regular"
          v-bind="attrs"
          v-on="on"
        >
          Бидний туxай
        </a>
      </template>
      <v-card height="300">
        <v-card-title class="headline"> Бидний туxай </v-card-title>
        <v-card-text>
          <iframe
            :src="selectedTodoVideoUrl"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="
              videoPlayDialog = false;
              selectedTodoVideoUrl = null;
            "
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="videoPlayDialog">
      <iframe
        width="40%"
        height="800"
        v-bind:src="selectedTodoVideoUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>

    <v-dialog v-model="showМeetLinkDialog" max-width="600">
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span>Багшийн онлайн ангийн линкийг оруулаx</span>
        </v-card-title>
        <v-card-text>
          <span class="red--text"
            >Под групп болон сонгон хичээлийн багшид хувийн онлайн линк үүсгэх.
            Энэxүү линкийг ашиглаж сурагчид багшийнxаан онлайн цаxим xурал,
            xичээлд оролцоxдоо ашиглаж болно.
          </span>
          <v-text-field
            v-model.trim="selectedTeacherOnlineLink"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showМeetLinkDialog = !showМeetLinkDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            v-if="userData.meetLink"
            dark
            @click="_deleteMeetLink"
            >Устгах</v-btn
          >
          <v-btn
            @click="_saveTeacherOnlineLink"
            class="bg-gradient-success text-capitalize"
            dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-container fluid class="px-6 py-6" v-else>
    <v-card class="mb-5 pb-10 pt-2">
      <div class="px-4 py-4 mt-0">
        <v-row>
          <v-col>
            <h1 class="text-h3 mb-4 font-weight-bold text-typo">
              Өдрийн ирц аймгийн нэгтгэл
            </h1>
          </v-col>
        </v-row>

        <v-row class="px-2">
          <v-col lg="6" sm="12" md="6">
            <v-row class="mt-4">
              <small
                @click="selectedMonth = month"
                small
                style="
                  cursor: pointer;
                  background: #dadada;
                  color: #000 !important;
                "
                class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                :class="
                  selectedMonth == month
                    ? 'bg-gradient-warning white--text'
                    : ''
                "
                :style="selectedMonth == month ? 'color: white' : ''"
                elevation="0"
                :ripple="false"
                v-for="(month, mindex) in months"
                :key="'month-' + mindex"
                >{{ month }}</small
              >
            </v-row>
          </v-col>
          <v-col class="text-end">
            <h1 class="text-h2 mb-4 font-weight-bold" style="color:#e91e63">
              {{ _getFullYearDate() }}
            </h1>
          </v-col>

          <v-col lg="2" sm="2" md="2">
            <v-select
              class="mt-2 pt-0"
              :items="days"
              v-model="selectedDay"
              return-object
              item-text="day"
              item-value="id"
              label="Өдөр сонгоx"
            >
              <template #item="{ item }">
                <v-row justif="space-between">
                  <v-col>{{ item.day }}</v-col>
                  <v-col class="text-end blue--text"
                    >{{ item.garagName }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.day }}
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="border-radius-xl card-shadow"
              style="background-color: #c8e6c9"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    ><h1 class="text-h4 pl-4 py-4 text-typo">
                      Ирсэн: {{ allPresents }}
                    </h1></v-col
                  >
                  <v-col cols="4">
                    <v-card style="background-color: #66bb6a" dark>
                      <h1 class="text-h5 pl-4 pt-0">
                        {{ ((allPresents / allStudents) * 100).toFixed(1) }}%
                      </h1>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              @click="_showTotalDetail('Тасалсан')"
              class="border-radius-xl card-shadow"
              style="background-color: #fce4ec"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 class="text-h4 pl-4 py-4 text-typo">
                      Тасалсан: {{ allAbsents }}
                    </h1>
                  </v-col>
                  <v-col cols="4">
                    <v-card style="background-color: #f8bbd0; color: #e91e63">
                      <h1 class="text-h5 pl-4 pt-0">
                        {{ ((allAbsents / allStudents) * 100).toFixed(1) }}%
                      </h1>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              @click="_showTotalDetail('Өвчтэй')"
              style="background-color: #fff9c4"
              class="border-radius-xl card-shadow"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 class="text-h4 pl-4 py-4 text-typo">
                      Өвчтэй: {{ allSick }}
                    </h1>
                  </v-col>
                  <v-col cols="4">
                    <v-card color="#FDD835">
                      <h1 class="text-h5 pl-4 pt-0 text-typo">
                        {{ ((allSick / allStudents) * 100).toFixed(1) }}%
                      </h1>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              @click="_showTotalDetail('Чөлөөтэй')"
              style="background-color: #bbdefb"
              class="border-radius-xl card-shadow"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 class="text-h4 pl-4 py-4 text-typo">
                      Чөлөөтэй: {{ allExcused }}
                    </h1>
                  </v-col>
                  <v-col cols="4">
                    <v-card color="#1E88E5">
                      <h1 class="text-h5 pl-4 pt-0 white--text">
                        {{
                          _getCustomizedProcent(
                            (allExcused / allStudents) * 100
                          )
                        }}%
                      </h1>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-simple-table class="mt-10">
          <thead>
            <tr>
              <th class="blue--text pl-1">No</th>
              <th class="pl-1">Сургуулийн нэр</th>
              <th class="pl-1">Гэрээний төлөв</th>
              <th class="pl-1">Бүлгийн тоо</th>
              <th class="pl-1">Ирцэд орсон бүлэг</th>
              <th class="pl-1 text-center">Бүртгэгдсэн сурагчид</th>
              <th class="pl-1 text-center">Ирсэн</th>
              <th style="color: #ffa500" class="pl-1 text-center">Өвчтэй</th>
              <th class="pl-1 blue--text text-center">Чөлөөтэй</th>
              <th class="red--text pl-1 text-center">Тасалсан</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(ss, ssIndex) in schools" :key="ss.id">
              <td style="width: 1%">{{ ssIndex + 1 }}</td>
              <td style="width: 30%">
                {{ ss.name }} 
              </td>
              <td>
                <small class="blue--text" v-if="ss._statusOfContract==0">Журам xүлээж байгаа!</small>
              </td>
              <td class="text-center">
                <span>{{ allClassGroups(ss).length }}</span>
              </td>
              <td
                class="text-center"
                v-if="
                  _getCustomizedProcent(
                    (_getRegisteredTotal(ss) / allClassGroups(ss).length) * 100
                  ) != null
                "
              >
                {{
                  _getCustomizedProcent(
                    (_getRegisteredTotal(ss) / allClassGroups(ss).length) * 100
                  )
                }}%
              </td>
              <td v-else class="red--text">Ирцгүй</td>

              <td v-if="ss.loading">
                <v-progress-linear
                  class="mb-4"
                  indeterminate
                  color="red"
                ></v-progress-linear>
              </td>
              <td class="text-center">{{ ss.totalStudents }}</td>
              <td style="background-color: #c8e6c9" class="text-center">
                {{ ss.studentsPresent }}
              </td>

              <td
                style="background-color: #fff9c4; cursor: pointer"
                class="text-center"
                @click="_detail(ss, 'Өвчтэй')"
              >
                {{ ss.studentsSick }}
              </td>
              <td
                style="background-color: #bbdefb; cursor: pointer"
                class="text-center"
                @click="_detail(ss, 'Чөлөөтэй')"
              >
                {{ ss.studentsExcused }}
              </td>
              <td
                style="background-color: #fce4ec; cursor: pointer"
                class="text-center"
                @click="_detail(ss, 'Тасалсан')"
              >
                {{ ss.studentsAbsent }}
              </td>
              <!-- {{ _getSickTotal(ss) }}   -->
              <!-- <td style="background-color: #BBDEFB;">{{ _getAbsentTotal(ss) }}  </td> -->
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
    <v-dialog
      v-model="showSchoolDetailDialog"
      scrollable
      max-width="60%"
      v-if="selectedSchool && this.selectedSchool.details"
    >
      <v-card>
        <v-card-title>
          <h3 class="text-typo">
            {{ selectedSchool.name }}
            /<span class="red--text" v-if="selectedSchool.details">
              {{ selectedSchool.details.length }} {{ selectedReasonText }}</span
            >
            /
          </h3>
          <v-spacer></v-spacer>

          <v-btn icon class="close-btn" @click="showSchoolDetailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <!-- Scrollable Content -->
        <v-card-text class="scrollable-content">
          <v-simple-table>
            <tbody>
              <tr
                style="border-bottom: 1px solid #bbb"
                v-for="(stud, studIndex) in selectedSchool.details"
                :key="stud.id"
              >
                <td style="width: 1%">{{ studIndex + 1 }}.</td>
                <td style="width: 30%">
                  <strong>{{ stud.FIRST_NAME }}</strong>
                </td>
                <td style="width: 30%">
                  {{ stud.LAST_NAME }}
                </td>
                <td class="blue--text">
                  {{ stud.STUDENT_GROUP_NAME }}
                </td>
                <td>
                  {{ stud.text }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <!-- Dialog Actions -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray-lighten-1"
            class="text-capitalize"
            @click="showSchoolDetailDialog = false"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAllSchoolDetailDialog"
      scrollable
      max-width="60%"
      v-if="selectedSchools"
    >
      <v-card>
        <v-card-title>
          <h3 class="text-typo">
            Дэлгэрэнгүй мэдээлэл - /
            <span class="red--text">
              {{ _getTotalSchoolCounter(selectedSchools) }}
              {{ selectedReasonText }}</span
            >
            /
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="close-btn"
            @click="
              selectedReasonText = null;
              selectedSchools = null;
              allStatus = null;
              showAllSchoolDetailDialog = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="scrollable-content">
          <table>
            <thead style="border-bottom: 3px solid #bbb">
              <tr class="font-weight-bold">
                <td>No.</td>
                <td style="width: 60%">Сургуулийн нэр</td>
                <td></td>
                <td>Тоо</td>
              </tr>
            </thead>

            <tbody>
              <tr
                class="mb-0"
                v-for="(ss, ssIndex) in selectedSchools"
                :key="ss.schoolId + ssIndex + 'dfsdfsdf'"
              >
                <td style="width: 1%">{{ ssIndex + 1 }}</td>
                <td style="width: 60%">
                  {{ ss.schoolName }}
                </td>
                <td></td>
                <td class="red--text">
                  {{ ss.counter }}
                </td>
                <!-- <td class="red--text">
                  {{_getCustomizedProcent(ss.counter/ss.studentsPresent*100)}}%
                </td> -->
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="scrollable-content">
          <v-simple-table>
            <tbody>
              <tr
                style="border-bottom: 1px solid #bbb"
                v-for="(stud, studIndex) in allStatus"
                :key="stud.studentId + 'dfasdfsf'"
              >
                <td style="width: 1%">{{ studIndex + 1 }}.</td>
                <td style="width: 20%">
                  <strong>{{ stud.FIRST_NAME }}</strong>
                </td>
                <td style="width: 20%">
                  {{ stud.LAST_NAME }}
                </td>

                <td class="blue--text" style="width: 30%">
                  {{ stud.schoolName }}
                </td>

                <td class="blue--text">
                  {{ stud.STUDENT_GROUP_NAME }}
                </td>
                <td>
                  {{ stud.text }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <!-- Dialog Actions -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray-lighten-1"
            class="text-capitalize"
            @click="
              selectedReasonText = null;
              selectedSchools = null;
              allStatus = null;
              showAllSchoolDetailDialog = false;
            "
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable */
import { sync } from "vuex-pathify";
import firebase from "firebase";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import axios from "axios";
import XLSX from "xlsx";
//npm install vue-xlsx --save
export default {
  name: "Dashboard",
  components: {},

  data: function () {
    return {
      selectedReasonText: null,
      selectedSchools: null,
      allStatus: null,
      showAllSchoolDetailDialog: false,
      selectedSchool: null,
      showSchoolDetailDialog: false,
      schools: null,
      selectedYear: null,
      selectedDay: null,
      selectedMonth: null,
      days: null,
      months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
    };
  },
  computed: {
    ...sync("*"),
    formattedDate() {
      return this.due ? format(this.due, "Do MMM YYYY") : "";
    },
    allStudents() {
      var total = 0;
      if (this.schools) {
        for (const ss of this.schools) {
          total = total + ss.totalStudents;
        }
      }
      return total;
    },
    allPresents() {
      var total = 0;
      if (this.schools) {
        for (const ss of this.schools) {
          total = total + ss.studentsPresent;
        }
      }
      if (total == 100) return 100;
      else return total;
    },
    allAbsents() {
      var total = 0;
      if (this.schools) {
        for (const ss of this.schools) {
          total = total + ss.studentsAbsent;
        }
      }
      return total;
    },
    allExcused() {
      var total = 0;
      if (this.schools) {
        for (const ss of this.schools) {
          total = total + ss.studentsExcused;
        }
      }
      return total;
    },
    allSick() {
      var total = 0;
      if (this.schools) {
        for (const ss of this.schools) {
          total = total + ss.studentsSick;
        }
      }
      return total;
    },
  },
  watch: {
    showSchoolDetailDialog(val) {
      if (val == false) {
        this.selectedSchool = null;
        this.selectedReasonText = null;
      }
    },
    showAllSchoolDetailDialog(val) {
      if (val == false) {
        this.selectedReasonText = null;
        this.selectedSchools = null;
        this.allStatus = null;
      }
    },
    selectedDay() {
      if (this.schools) {
        for (const school of this.schools) {
          school.totalStudents = 0;
          school.studentsPresent = 0;
          school.studentsAbsent = 0;
          school.studentsExcused = 0;
          school.studentsSick = 0;
          school.loading = true;
          for (const dep of school.departments) {
            for (const cg of dep.classGroups) {
              school.loading = true;
              cg.attendanceStatistics = null;
              cg.ref
                .collection("attendances-2024")
                .doc(
                  this.selectedDay.year +
                    "-" +
                    this.selectedMonth +
                    "-" +
                    this.selectedDay.day
                )
                .onSnapshot((doc) => {
                  if (doc.exists) {
                    cg.attendanceStatistics = doc.data();
                    school.studentsSick =
                      school.studentsSick + doc.data()["studentsSick"];
                    school.studentsAbsent =
                      school.studentsAbsent + doc.data()["studentsAbsent"];
                    school.studentsExcused =
                      school.studentsExcused + doc.data()["studentsExcused"];

                    school.studentsPresent =
                      school.studentsPresent + doc.data()["studentsPresent"];
                    school.totalStudents =
                      school.totalStudents + doc.data()["totalStudents"];
                  } else {
                    console.log("Irzgui odoruud");
                  }
                  school.loading = false;
                  this.$forceUpdate();
                });
            }
          }
        }
      }
    },
    selectedMonth() {
      this.days = [];
      for (const ss of this.$store.state.calendarButez2) {
        for (const mm of ss.months) {
          if (mm.name == this.selectedMonth) {
            for (const dd of mm.days) {
              if (!dd.isHoliday) this.days.push(dd);
            }
          }
        }
      }
    },
  },
  created() {
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedDay = { day: new Date().getDate() };
    this.selectedYear = new Date().getFullYear();
    console.log("22222");
    // this.userData.cityCode = "03"
    fb.db
      .collection("schools")
      .where("cityCode", "==", this.userData.cityCode)
      .orderBy("name", "asc")
      .get()
      .then((docs) => {
        this.schools = [];
        docs.forEach((doc) => {
          let ss = doc.data();
          ss.id = doc.id;
          ss.ref = doc.ref;
          this._getDepartments(ss);
          this.schools.push(ss);
        });
      });
    // this._getDepartments(this.$store.state.userData.school.currentYear);
  },
  methods: {
    _getTotalSchoolCounter(schools) {
      return schools.reduce((sum, school) => sum + school.counter, 0);
    },
    _showTotalDetail(reason) {
      console.log(reason);
      this.allStatus = [];
      this.selectedSchools = [];
      for (const ss of this.schools) {
        for (const dep of ss.departments) {
          for (const cg of dep.classGroups) {
            if (cg.attendanceStatistics && cg.attendanceStatistics._details) {
              for (const dd of cg.attendanceStatistics._details) {
                if (dd.text == reason) {
                  dd.schoolName = ss.name;
                  dd.schoolId = ss.id;
                  this.allStatus.push(dd);
                  var found = this.selectedSchools.find(
                    (s) => String(s.schoolId) == String(ss.id)
                  );
                  if (found) {
                    found.counter = found.counter + 1;
                  } else {
                    this.selectedSchools.push({
                      schoolName: ss.name,
                      studentsPresent: ss.studentsPresent,
                      counter: 1,
                      schoolId: ss.id,
                    });
                  }
                }
              }
            }
          }
        }
      }
      this.selectedSchools.sort((a, b) => b.counter - a.counter);
      this.showAllSchoolDetailDialog = true;
      this.selectedReasonText = reason;
    },
    _detail(ss, reason) {
      console.log(ss, reason);
      var list = [];
      for (const dep of ss.departments) {
        for (const cg of dep.classGroups) {
          if (cg.attendanceStatistics && cg.attendanceStatistics._details) {
            for (const dd of cg.attendanceStatistics._details) {
              if (dd.text == reason) {
                list.push(dd);
              }
            }
          }
        }
      }
      this.selectedSchool = ss;
      this.selectedSchool.details = list;
      this.selectedReasonText = reason;
      this.showSchoolDetailDialog = true;
      console.log(list.length, "dfljsdf");
    },
    _getCustomizedProcent(number) {
      if (number == 0) return null;
      else if (number == 100) return 100;
      return number.toFixed(1);
    },
    customRound(number, precision) {
      if (precision) {
        var factor = Math.pow(10, precision);
      }

      return Math.round(number * factor) / factor;
    },

    _getFullYearDate() {
      return (
        this.selectedYear +
        "-" +
        this.selectedMonth +
        "-" +
        this.selectedDay.day
      );
    },
    allClassGroups(school) {
      var list = [];
      var counter = 0;
      if (school.departments) {
        for (var dep of school.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              counter++;
              prog.index = counter;
              prog.name = prog.STUDENT_GROUP_NAME;
              if (!prog.deleted) {
                list.push(prog);
              }
            }
        }
      }
      return list;
    },
    _getRegisteredTotal(school) {
      var total = 0;
      if (school.departments) {
        for (var dep of school.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              prog.name = prog.STUDENT_GROUP_NAME;
              if (!prog.deleted) {
                if (prog.attendanceStatistics) total++;
              }
            }
        }
      }
      return total;
    },
    _getSickTotal(school) {
      var total = 0;
      if (school.departments) {
        for (var dep of school.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              prog.name = prog.STUDENT_GROUP_NAME;
              if (!prog.deleted) {
                if (
                  prog.attendanceStatistics &&
                  prog.attendanceStatistics["studentsSick"]
                )
                  total = total + prog.attendanceStatistics["studentsSick"];
              }
            }
        }
      }
      return total;
    },
    _getAbsentTotal(school) {
      var total = 0;
      if (school.departments) {
        for (var dep of school.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              prog.name = prog.STUDENT_GROUP_NAME;
              if (!prog.deleted) {
                if (
                  prog.attendanceStatistics &&
                  prog.attendanceStatistics["studentsAbsent"]
                )
                  total = total + prog.attendanceStatistics["studentsAbsent"];
              }
            }
        }
      }
      return total;
    },
    _getOverAllStatistics(allClassGroups) {
      var overAll = {
        registeredClassGroups: 0,
        totalStudents: 0,
        studentsPresent: 0,
        studentsSick: 0,
        studentsExcused: 0,
        studentsAbsent: 0,
        studentsPresent2: 0,
        studentsSick2: 0,
        studentsExcused2: 0,
        studentsAbsent2: 0,
      };
      var counter = 0;
      if (allClassGroups) {
        for (const classGroup of allClassGroups) {
          if (classGroup.attendanceStatistics) {
            counter++;
            if (
              classGroup.attendanceStatistics["totalStudents"] &&
              classGroup.attendanceStatistics["totalStudents"] > 0
            )
              overAll["totalStudents"] =
                overAll["totalStudents"] +
                classGroup.attendanceStatistics["totalStudents"];

            if (
              classGroup.attendanceStatistics["studentsPresent"] &&
              classGroup.attendanceStatistics["studentsPresent"] > 0
            )
              overAll["studentsPresent"] =
                overAll["studentsPresent"] +
                classGroup.attendanceStatistics["studentsPresent"];

            if (
              classGroup.attendanceStatistics["studentsSick"] &&
              classGroup.attendanceStatistics["studentsSick"] > 0
            )
              overAll["studentsSick"] =
                overAll["studentsSick"] +
                classGroup.attendanceStatistics["studentsSick"];

            if (
              classGroup.attendanceStatistics["studentsExcused"] &&
              classGroup.attendanceStatistics["studentsExcused"] > 0
            )
              overAll["studentsExcused"] =
                overAll["studentsExcused"] +
                classGroup.attendanceStatistics["studentsExcused"];

            if (
              classGroup.attendanceStatistics["studentsAbsent"] &&
              classGroup.attendanceStatistics["studentsAbsent"] > 0
            )
              overAll["studentsAbsent"] =
                overAll["studentsAbsent"] +
                classGroup.attendanceStatistics["studentsAbsent"];
          }
        } //for
        overAll["registeredClassGroups"] = counter;
        if (overAll["totalStudents"] > 0) {
          overAll["studentsPresent2"] = this.customRound(
            (overAll["studentsPresent"] / overAll["totalStudents"]) * 100,
            1
          );
        } else {
          overAll["studentsPresent2"] = this.customRound(
            (overAll["studentsPresent"] / 1) * 100,
            1
          );
        }

        if (overAll["totalStudents"] > 0) {
          overAll["studentsSick2"] = this.customRound(
            (overAll["studentsSick"] / overAll["totalStudents"]) * 100,
            1
          );
        } else {
          overAll["studentsSick2"] = this.customRound(
            (overAll["studentsSick"] / 1) * 100,
            1
          );
        }

        if (overAll["totalStudents"] > 0) {
          overAll["studentsExcused2"] = this.customRound(
            (overAll["studentsExcused"] / overAll["totalStudents"]) * 100,
            1
          );
        } else {
          overAll["studentsExcused2"] = this.customRound(
            (overAll["studentsExcused"] / 1) * 100,
            1
          );
        }

        if (overAll["totalStudents"] > 0) {
          overAll["studentsAbsent2"] = this.customRound(
            (overAll["studentsAbsent"] / overAll["totalStudents"]) * 100,
            1
          );
        } else {
          overAll["studentsAbsent2"] = this.customRound(
            (overAll["studentsAbsent"] / 1) * 100,
            1
          );
        }
      }
      return overAll;
    },
    _getDepartments(school) {
      school.totalStudents = 0;
      school.studentsPresent = 0;
      school.studentsAbsent = 0;
      school.studentsExcused = 0;
      school.studentsSick = 0;

      school.loading = true;
      school.departments = null;
      school.ref
        .collection("departments-2024")
        .orderBy("index", "asc")
        .get()
        .then((querySnapshot) => {
          school.departments = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            school.loading = true;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  prog.attendanceStatistics = null;
                  school.loading = true;
                  prog.ref
                    .collection("attendances-2024")
                    .doc(this._getFullYearDate())
                    .onSnapshot((doc) => {
                      if (doc.exists) {
                        prog.attendanceStatistics = doc.data();
                        school.studentsSick =
                          school.studentsSick + doc.data()["studentsSick"];
                        school.studentsAbsent =
                          school.studentsAbsent + doc.data()["studentsAbsent"];
                        school.studentsExcused =
                          school.studentsExcused +
                          doc.data()["studentsExcused"];

                        school.studentsPresent =
                          school.studentsPresent +
                          doc.data()["studentsPresent"];
                        school.totalStudents =
                          school.totalStudents + doc.data()["totalStudents"];
                      }
                      school.loading = false;
                    });
                  dep.classGroups.push(prog);
                });
                school.loading = false;
              });
            school.departments.push(dep);
          });
          school.loading = false;
        });
    },
  },
};
</script>
<style>
.custom-cancel-button {
  color: white; /* Change text color */
  background-color: red; /* Change background color */
  /* Add any other styles you need */
}
.kkk {
  border-radius: 5px;
  border: 2px solid red;
}
.scrollable-content {
  max-height: 500px; /* Increased height */
  overflow-y: auto;
  padding: 16px;
  border-bottom: 1px solid #ddd; /* Optional divider below content */
}
</style>
